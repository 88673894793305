<template>
  <div class="p-30">
    <div v-if="insurances" class="my-insurance">
    <a-page-header
      v-for="item in insurances"
      v-bind:key="item.id"
      v-bind:title="item.no"
      class="my-insurance-item"
    >
      <template slot="tags">
        <a-tag color="blue">
          <!-- {{ item.type }}  -->
          <span v-if="item.type === 'SINGLE'">{{ $t("label.single") }}</span>
          <span v-else>{{ $t("label.family") }}</span>
        </a-tag>
      </template>
      <a-row :gutter="18">
        <a-col :span="18" :md="9" :lg="6">
          <a-statistic
            class="mt-15"
            :title="$t('label.price')"
            prefix="₭"
            v-bind:value="item.amount"
            valueStyle=" font-family:'NotoSans'; font-size: 20px"
          >
            <template #suffix>
              <a-tag :color="statusTagColor(item.status)">
                {{ item.status }}
              </a-tag>
            </template></a-statistic
          ></a-col
        >
        <a-col :span="18" :md="9" :lg="6">
          <a-statistic
            class="mt-15"
            :title="$t('label.product')"
            v-bind:value="item.insurancetype.name"
            valueStyle=" font-family:'NotoSans'; font-size: 20px "
          />
        </a-col>
        <a-col :span="18" :md="9" :lg="6">
          <a-statistic
            class="mt-15"
            :title="$t('label.insurancePackage')"
            v-bind:value="item.insurancepackage.name"
            valueStyle=" font-family:'NotoSans';font-size: 20px "
          />
        </a-col>
        <a-col :span="18" :md="9" :lg="6">
          <a-statistic
            class="mt-15"
            :title="$t('label.createdDate')"
            v-bind:value="formatDate(item.createdtime)"
            valueStyle=" font-family:'NotoSans'; font-size: 20px "
          />
        </a-col>
      </a-row>
      <div class="view">
        <a-button type="primary" icon="eye" @click="viewDetails(item.id)">
          {{ $t("action.viewButton") }}
        </a-button>
      </div>
    </a-page-header>
    <ViewDetailModal :visible="visible" :certificateId="certificateId" @close="handleCloseDetail"/>
  </div>
  <div v-else>
    <Loading/>
  </div>
  </div>
</template>

<script>
import InsuranceService from "../../services/insurance.service";
import { formatDate, statusTagColor } from "../../utils/helper";
import ViewDetailModal from "../../components/Modals/ViewDetailModal.vue"
import { message } from "ant-design-vue";
import Loading from "../../components/Loading/Loading.vue";

export default {
  name: "MyInsurance",
  components: {
    ViewDetailModal,
    Loading
  },
  data() {
    return {
      insurances: null,
      visible: false,
      certificateId: 0,
    };
  },
  created() {
    this.getMyInsurance();
  },
  methods: {
    async getMyInsurance() {
      // this.isLoading = true;
        await InsuranceService.getCertificates()
          .then((res) => {
            // console.log("res.data:",res.data)
            this.insurances = res.data;
            // this.isLoading = false;
          })
          .catch((err) => {
            message.error(err.message);
            // this.isLoading = false;
          })
      // }
    },
    viewDetails(insurance_id) {
      this.certificateId = insurance_id;
      this.visible = true;
    },
    handleCloseDetail(){
      this.visible = false;
      this.certificateId = 0
    },
    formatDate,
    statusTagColor
  },
};
</script>

<style lang="scss" scoped>
.my-insurance-item {
  border: 2px solid rgb(235, 237, 240);
  margin-bottom: 20px;
  box-shadow: 0.5px 4px 6px rgba(0, 0, 0.12, 0.12);
  transition: box-shadow 0.3s ease;
  opacity: 1;
  transition: opacity 0.5s;

  .view {
    text-align: right;
    color: green;
  }
}
.my-insurance-item:hover {
  box-shadow: 0 0 10px gray;
  opacity: 0.8;
  // background-color: gray;
}
</style>
