<template>
    <canvas ref="qrCanvas"></canvas>
</template>
  
<script>
  import QRCode from "qrcode";

  export default {
    name: "QRCode",
    props: {
      qrText: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.generateQRCode();
    },
    watch: {
    qrText(newVal) {
        this.generateQRCode();
    },
  },
    methods: {
        generateQRCode () {
        if (!this.$refs.qrCanvas) {
          console.error("QR Canvas not found");
          return;
        }
  
        QRCode.toCanvas(this.$refs.qrCanvas, this.qrText, { width: 180 }, (error) => {
          if (error) console.error(error);
        });
      },
    },
};
</script>
  