<template>
  <a-modal 
    :title="$t('label.myInsuranceDetail')"
    :dialog-style="{ top: '20px' }"
    :width="600"
    :visible="visible"
    :footer="null"
    @cancel="onClose">
    <div v-if="insurance">
      <a-row :gutter="24" style="width: auto">
        <a-col :span="24" :sm="24" :md="12">
          <div style="width: auto; text-align: center">
            <QRCode :qrText="insurance.no"/>
            <p style="margin-top: -1.7rem; font-weight: 600;">{{ insurance.no }}</p>
          </div>
        </a-col>
        <a-col :span="24" :sm="24" :md="12">
          <div style="width: auto; text-align: center; margin: 20px 0 15px 0">
            <Barcode :barcodeText="insurance.no"/>
          </div>
        </a-col>
      </a-row>

      <div class="mt-20">
        <a-descriptions title="" :column="2" style="text-align: left">
          <a-descriptions-item :label="$t('label.createdDate')">
            {{ formatDate(insurance.createdtime) }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('label.product')">
            {{ insurance.insurancetype.name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('label.insurancePackage')">
            {{ insurance.insurancepackage.name }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('label.type')">
            {{ insurance.type }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('label.amount')">
            {{ formatMoney(insurance.amount) }}
          </a-descriptions-item>
          <a-descriptions-item :label="$t('label.status')">
            <a-tag :color="statusTagColor(insurance.status)">
              {{ insurance.status }}
            </a-tag>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <a-divider></a-divider>
      <div
        v-if="insurance.status === 'PENDING'"
        style="text-align: left"
      >
        <a-button
          icon="dollar"
          block
          @click="payNow()"
          style="color: white; background-color: #008000; max-width: fit-content; font-size: 18px;"
          >{{ $t("button.payNow") }}</a-button
        >
      </div>
      <div
        style="
          margin-top: 15px;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
          padding: 10px;
        "
      >
        <h5 style="text-align: center; padding-top: 10px">
          {{ $t("label.listMember") }}
        </h5>
        <CardMemberList :user="insurance.certificatemember" :certificateNo="insurance.no" />
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
  </a-modal>
</template>

<script>
import CardMemberList from "../../components/Cards/CardMemberList.vue";
import insuranceService from "../../services/insurance.service";
import { formatDate, statusTagColor, formatMoney } from "../../utils/helper";
import Loading from "../../components/Loading/Loading.vue";
import { message } from "ant-design-vue";
import QRCode from '../QRs/QRCode.vue';
import Barcode from "../QRs/Barcode.vue";
import {baseUrl} from "../../utils/helper"

export default {
  name: "ViewDetailModal",
  components: {
    CardMemberList,
    Loading,
    QRCode,
    Barcode
  },
  props: {
    certificateId: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      insurance: null,
    };
  },
  created() {
    this.getCertificate();
  },
  watch: {
    certificateId(newId) {
      if (newId) {
        this.getCertificate(newId);
      }
    },
  },
  methods: {
    async getCertificate() {
        await insuranceService.getCertificateById(this.certificateId)
        .then((res) =>{
          this.insurance = res.data
        }).catch ((err) => {
        message.error(err.message);
      })
    },
    formatDate,
    statusTagColor,
    formatMoney,
    onClose() {
      this.$emit('close');
      this.insurance = null
    },
    payNow() {
      return this.$router.push(`/payment/${this.insurance.id}`);
    },
  },
};
</script>
