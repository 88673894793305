<template>
    <div>
      <canvas ref="barcodeCanvas" style="max-width: 240px"></canvas>
    </div>
  </template>
  
  <script>
  import JsBarcode from "jsbarcode";
  
  export default {
    name: "Barcode",
    props: {
      barcodeText: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.generateBarcode();
    },
    watch: {
    barcodeText(newVal) {
       this.generateBarcode();
      },
     },
    methods: {
      generateBarcode() {
        if (this.$refs.barcodeCanvas) {
          JsBarcode(this.$refs.barcodeCanvas, this.barcodeText, {
            format: "CODE128",
            width: 2,
            height: 120,
          });
        } else {
          console.error("Barcode Canvas not found");
        }
      },
    },
  };
  </script>
  