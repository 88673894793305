<template>
  <!-- Member List Card -->
  <div>
    <a-card
      :bordered="false"
      :bodyStyle="{ paddingTop: 0, paddingBottom: '10px' }"
    >
      <!-- <template #title>
      <h6 class="font-semibold m-0">Member</h6>
    </template> -->

      <a-list
        item-layout="horizontal"
        :split="false"
        :dataSource="user"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta
            :title="item.firstname + ' ' + item.lastname"
            :description="formatDate(item.dob)"
          >
            <a-avatar
              style="background-color: #808080; border-radius: 50px"
              slot="avatar"
              :size="48"
            >
              <a-icon slot="icon" type="user" />
            </a-avatar>
          </a-list-item-meta>
          <a-button type="primary" size="small" @click="onPrintCertificate(item)">
            {{ $t('button.policySchedule') }}
          </a-button>
        </a-list-item>
        <a-list-item class="pl-10" v-for="(item, index) in member" :key="index">
          <a-button
            style="color: red"
            icon="delete"
            slot="actions"
            type="link"
            @click="deleteMember"
          >
            {{ $t("action.DeleteButton") }}
          </a-button>
          <a-list-item-meta
            :title="item.firstname + ' ' + item.lastname"
            :description="item.dob.format('YYYY-MM-DD')"
          >
            <a-avatar
              style="background-color: #008000; border-radius: 50px"
              slot="avatar"
              :size="48"
            >
              <a-icon slot="icon" type="user" />
            </a-avatar>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </a-card>
  </div>

  <!-- / Member List Card -->
</template>

<script>
import { baseUrl, formatDate } from "../../utils/helper";

export default {
  props: {
    user: {
      type: Array,
      default: () => [],
    },
    certificateNo: {
      type: String,
      default: null,
      require: true,
    },
    member: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      removeMember: this.member,
    };
  },
  watch: {
    members(newValue) {
      this.removeMember = newValue;
    },
  },
  methods: {
    deleteMember() {
      this.$emit("remove");
    },
    formatDate,
    onPrintCertificate(item) {
      window.open(`${baseUrl}/policyschedule?id=${item.certificate_id}&no=${this.certificateNo}&member=${item.seq}`, '_blank');
    },
  },
};
</script>
