import {baseUrl} from '../utils/helper';
import axios from './axios-interceptor';

class InsuranceService {
    getCertificates(){
      return axios.get(`${baseUrl}/myinsurance/gets`)
    }
    getCertificateById(id){
      return axios.get(`${baseUrl}/myinsurance/get/${id}`)
    }
    getMemberCertificate(){
      return axios.get(`${baseUrl}/myinsurance/getmembers/${id}`)
    }
  }
  
  export default new InsuranceService();